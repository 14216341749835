<template>
  <div class="d-flex">
    <!-- <v-card outlined class="pa-2 mr-2 body-2 text-no-wrap" max-width="100">
      總數 <span class="error--text ml-1">{{ totalManDay }}</span>
    </v-card> -->
    <v-card
      v-for="item in manDaySummary"
      :key="item.text"
      outlined
      class="pa-2 mr-2 body-2 text-no-wrap"
      max-width="100"
    >
      {{ item.text }}
      <span v-if="item.text != '總數'"
        >(<span class="grey--text">天</span>)</span
      >
      <span class="error--text ml-2">{{ item.value }}</span>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    manDaySummary: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    // totalManDay() {
    //   if (this.manDaySummary.length == 0) return 0;
    //   let total = this.manDaySummary.reduce((acc, cur) => {
    //     acc += cur.value;
    //     return acc;
    //   }, 0);
    //   return total;
    // },
  },
};
</script>
