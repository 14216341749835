<template>
  <v-container fluid>
    <v-row>
      <v-col class="titleWidth">
        <version-status-title
          title="稽核計畫｜"
          :versionData="versionData"
        ></version-status-title>
      </v-col>
      <v-col
        v-if="!isFromSignOff()"
        class="d-flex align-center justify-end pt-0 pb-1"
      >
        <v-btn
          v-show="!editMode"
          @click="signHistory"
          text
          color="primary"
          class="mr-5 pa-0"
          >簽核軌跡</v-btn
        >
        <v-btn to="/audit-plan" depressed class="mr-2">返回列表</v-btn>
        <v-btn
          v-if="
            versionData.status == 0 &&
            ((versionData.group == '分公司' &&
              rolesCheck('auditPlanByIdGroupStatus')) ||
              (versionData.group != '分公司' &&
                rolesCheck('auditPlanByIdStatus')))
          "
          @click="checkHasEditItem(1)"
          depressed
          class="mr-2"
          color="primary"
          >製作初稿</v-btn
        >
        <v-btn
          v-if="
            versionData.status == 1 &&
            ((versionData.group == '分公司' &&
              rolesCheck('auditPlanByIdGroupStatus')) ||
              (versionData.group != '分公司' &&
                rolesCheck('auditPlanByIdStatus')))
          "
          @click="checkHasEditItem(0)"
          depressed
          class="mr-2"
          color="primary"
          outlined
          >修改</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="flex-nowrap">
      <v-col style="max-width: 224px; min-width: 224px">
        <div class="riskLevCardsParent">
          <div class="riskLevCards">
            <risk-lev-year-card
              v-for="item in auditPlanSummary"
              :key="item.year"
              :item="item"
              :searchYear="filters.searchYear"
              @changeSearchYear="changeSearchYear"
            ></risk-lev-year-card>
          </div>
        </div>
      </v-col>
      <v-col class="py-0">
        <div class="d-flex mb-2 align-center">
          <h3 class="text-h3 ml-2 mr-5 my-4">{{ filters.searchYear }}</h3>
          <v-select
            v-model="filters.type"
            :items="riskTypes"
            outlined
            dense
            class="inputWhiteBg filterInput mr-2"
            hide-details
            label="風險類型"
            clearable
            :menu-props="{ offsetY: true }"
          ></v-select>
          <v-select
            v-model="filters.item"
            :items="riskItems"
            outlined
            dense
            class="inputWhiteBg filterInput mr-2"
            hide-details
            label="風險項目"
            clearable
            :disabled="!filters.type"
            :menu-props="{ offsetY: true }"
          ></v-select>
          <v-select
            v-model="filters.scheduleYear"
            :items="scheduleYears"
            outlined
            dense
            class="inputWhiteBg filterInput mr-2"
            hide-details
            label="排定年分"
            clearable
            :menu-props="{ offsetY: true }"
          ></v-select>
          <v-select
            v-model="filters.level"
            :items="rrsLevels"
            outlined
            dense
            class="inputWhiteBg filterInput"
            hide-details
            label="剩餘風險等級"
            clearable
            :menu-props="{ offsetY: true }"
          ></v-select>
        </div>
        <div class="d-flex mb-3">
          <man-days :manDaySummary="manDaySummary"></man-days>
          <v-btn
            @click="headerHandlerDialog = true"
            class="mr-2 ml-auto"
            color="primary"
            outlined
            style="background: #fff"
            >欄位顯示
          </v-btn>
          <v-btn
            v-if="!isFromSignOff() && rolesCheck('auditPlanByIdImport')"
            @click="importDialogOpen"
            color="primary"
            >匯入</v-btn
          >
        </div>
        <material-table
          class="material-table auditPlan_table"
          :headers="displayHeader"
          :items="item_list"
          :options.sync="pageMeta.options"
          :items-per-page="-1"
        >
          <template #factor="{ item }">{{ item.factor }}</template>
          <template #residualRisk="{ item }">{{ item.residualRisk }}</template>
          <template #period="{ item }">{{ item.period }}</template>
          <template #nextAuditYear="{ item }">
            {{ item.nextAuditYear }}
          </template>
          <template #scheduleYear="{ item }">
            <div v-if="!item.edit">
              <span v-if="item.nextAuditYear == item.scheduleYear">{{
                item.scheduleYear
              }}</span>
              <span v-else class="error--text">{{ item.scheduleYear }}</span>
            </div>
            <v-select
              v-else
              v-model="item.scheduleYear"
              :items="scheduledList"
              outlined
              class="fs-13 inputWhiteBg"
              style="max-width: 85px"
              hide-details
              single-line
              dense
              :menu-props="{ offsetY: true }"
            ></v-select>
          </template>
          <template #manDay="{ item }">
            <span v-if="!item.edit">{{ item.manDay }}</span>
            <v-text-field
              v-else
              v-model="item.manDay"
              outlined
              class="fs-13 inputWhiteBg"
              hide-details
              single-line
              dense
            >
            </v-text-field>
          </template>
          <template #notes="{ item }">
            <span v-if="!item.edit">{{ item.notes || "-" }}</span>
            <div v-if="item.edit" class="d-flex align-end mb-4">
              <v-textarea
                v-model="item.notes"
                class="mt-3 fs-13"
                rows="1"
                auto-grow
                dense
              ></v-textarea>
            </div>
          </template>
          <template #actions="{ item }">
            <div class="d-flex">
              <v-icon
                v-if="item.edit"
                @click="editFactor(item)"
                class="pointer mr-2"
                >mdi-content-save</v-icon
              >
              <v-icon
                v-if="item.edit"
                @click="item.edit = false"
                class="pointer"
                >mdi-close-circle</v-icon
              >
              <v-icon
                v-else
                @click="item.edit = true"
                class="pointer"
                :disabled="
                  versionData.status != 0 ||
                  isFromSignOff() ||
                  !rolesCheck('auditPlanByIdEdit')
                "
                >mdi-pencil</v-icon
              >
            </div>
          </template>
        </material-table>

        <material-pagination
          margin="my-2"
          :totalPages="pageMeta.totalPages"
          @pageFilter="pageFilter"
        ></material-pagination>
      </v-col>
    </v-row>
    <!-- === Dialogs === -->
    <!-- 簽核軌跡 -->
    <dialog-history
      :dialog="signHistoryDialog"
      :historyData="historyData"
      @close="signHistoryDialog = false"
    ></dialog-history>
    <!-- 欄位顯示 -->
    <material-dialog
      v-model="headerHandlerDialog"
      title="欄位顯示"
      max-width="350"
    >
      請選擇欲顯示之欄位
      <v-row class="px-4 py-2" no-gutters>
        <v-col v-for="field in fieldHandler" :key="field.value" cols="6">
          <v-checkbox
            v-model="field.checked"
            :label="field.text"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <template #actions>
        <v-spacer></v-spacer>
        <v-btn @click="headerHandlerDialog = false" depressed color="primary"
          >確認</v-btn
        >
      </template>
    </material-dialog>
    <!-- 匯入資料 -->
    <dialog-import
      @close="importDialog.open = false"
      @reset="importDialog.errors = []"
      @submit="importSubmit"
      v-bind="importDialog"
    ></dialog-import>
  </v-container>
</template>
<script>
/*
Q：
1.patch api ,audit id 404
2.rrs value
3.切換卡片 query key
TODO:
1.selects ok
2.pagination ok
3.製作初稿 ok
4.簽核軌跡
5.編輯儲存 ok
6.簽核
*/
import { sync } from "vuex-pathify";
import RiskLevYearCard from "./components/RiskLevYearCard.vue";
import {
  fromSignOffMixin,
  versionMixin,
  checkRolesMixin,
  searchMixin,
} from "@/helper";
import ManDays from "./components/ManDays.vue";
import {
  apiGetAuditPlanById,
  apiGetAuditPlanSummary,
  apiToggleAuditPlanStatus,
  apiEditFactor,
} from "@/api/audit-plan";
import { apiGetRiskEvaluationById } from "@/api/risk-evaluation";
import { apiGetAllocationSettingsById } from "@/api/allocation";

export default {
  components: { RiskLevYearCard, ManDays },
  mixins: [fromSignOffMixin, versionMixin, checkRolesMixin, searchMixin],
  data() {
    return {
      versionData: {
        year: "2022",
        no: "01",
        group: "車險商品部",
        status: 0,
      },
      manDaySummary: [],

      expanded: [],
      action: null,
      editMode: false,
      header: [
        { text: "查核因子", value: "factor", sortable: false, width: 200 },
        { text: "剩餘風險", value: "residualRisk", sortable: false, width: 50 },
        { text: "RRS等級", value: "rrsLevel", sortable: false, width: 50 },
        { text: "週期", value: "period", sortable: false, width: 50 },
        {
          text: "上次年分",
          value: "lastAuditYear",
          sortable: false,
          width: 50,
        },
        {
          text: "預計年分",
          value: "nextAuditYear",
          sortable: false,
          width: 50,
        },
        { text: "排定年分", value: "scheduleYear", sortable: false, width: 50 },
        { text: "人力(天)", value: "manDay", sortable: false, width: 50 },
        { text: "補充信息", value: "notes" },
        { text: "操作", value: "actions", sortable: false, width: 50 },
      ],
      fieldHandler: [
        { text: "剩餘風險", value: "residualRisk", checked: true },
        { text: "RRS等級", value: "rrsLevel", checked: true },
        { text: "週期", value: "period", checked: true },
        { text: "上次年分", value: "lastAuditYear", checked: true },
        { text: "預計年分", value: "nextAuditYear", checked: true },
      ],
      item_list: [
        // {
        //   id: 1,
        //   factor: "未依商品送審費率計收保費。",
        //   residualRisk: 7.05,
        //   rrsLevel: "中",
        //   period: "2",
        //   lastAuditYear: 2020,
        //   nextAuditYear: 2022,
        //   scheduleYear: 2023,
        //   manDay: "0.1",
        //   notes: "將於下半年提出",
        //   edit: false,
        // },
      ],
      // searchYear: null,
      executeYear: null,
      auditPlanSummary: [],
      // === Filters ===
      riskEvaluationList: [],
      riskTypes: [],
      riskItems: [],
      scheduleYears: [],
      rrsLevels: [],
      // === Pagination ===
      pageMeta: {
        options: {
          sortDesc: [true],
        },
        page: 1,
        totalPages: 1,
        itemsPerPage: 10,
      },
      filters: {
        type: null,
        item: null,
        scheduleYear: null,
        level: null,
        searchYear: null,
      },
      // === Dialogs ===
      //簽核軌跡
      signHistoryDialog: false,
      historyData: {
        year: "2022",
        No: "2",
        history: [
          {
            id: 1,
            role: "行政人員",
            name: "陳晉文",
            status: 0,
            desc: "",
            updatedAt: "2022-10-24 11:53:28",
          },
          {
            id: 2,
            role: "副總稽核",
            name: "錢伯文",
            status: 2,
            desc: "批改作業的批打錯了",
            updatedAt: "2022-10-24 11:53:28",
          },
          {
            id: 3,
            role: "副總稽核",
            name: "錢伯文",
            status: 1,
            desc: "",
            updatedAt: "2022-10-24 11:53:28",
          },
        ],
      },
      //顯示欄位
      headerHandlerDialog: false,
      //匯入資料
      importDialog: {
        // open: false,
        // type: "auditPlan",
        // errors: [],
      },
    };
  },
  computed: {
    ...sync("global", ["alert"]),
    auditPlanId() {
      return this.$route.params.id;
    },
    mini: sync("app/mini"),
    displayHeader() {
      let newH = this.header.filter((el) => {
        let showF = true;
        this.fieldHandler.forEach((el2) => {
          if (el.value == el2.value && !el2.checked) {
            showF = false;
          }
        });
        return showF;
      });
      //   console.log({ newH });
      return newH;
    },
    scheduledList() {
      const thisYear = new Date().getFullYear();
      let yearList = [];
      for (let i = 0; i < 3; i++) {
        yearList.push((thisYear + i).toString());
      }
      console.log({ yearList });
      return yearList;
    },
  },
  watch: {
    mini(val) {
      let hasEditItem = this.item_list.find((el) => el.edit);
      if (!val && hasEditItem) {
        // TODO: reset item
        this.item_list.forEach((el) => (el.edit = false));
      }
    },
    // Filters
    filters: {
      handler() {
        this.getAuditPlanById();
      },
      deep: true,
    },
    "filters.type": function (val) {
      console.log({ val });
      if (val == null) {
        if (this.filters.item) this.filters.item = null;
        return;
      }
      this.riskItems = this.riskEvaluationList.reduce((acc, cur) => {
        if (cur.type == val) {
          acc.push(cur.item);
        }
        return acc;
      }, []);
    },
    "pageMeta.options": {
      handler() {
        this.getAuditPlanById();
      },
    },
  },
  async mounted() {
    await this.getAuditPlanById("init");
    await this.getAuditPlanSummary();
    await this.getRiskEvaluationList();
    this.getScheduleYears();
  },
  methods: {
    // Table Data
    async getAuditPlanById(init) {
      if (init == "init") {
        this.filters.searchYear = this.getThisYear().toString();
      }
      try {
        let payload = this.combineQueries(this.pageMeta, this.filters);
        let res = await apiGetAuditPlanById(this.auditPlanId, payload);
        console.log("getAuditPlanById", { res });
        let data = res.data;
        this.versionData = data.auditPlan;
        this.manDaySummary = data.manDaySummary;
        data.content.forEach((el) => (el.edit = false));
        this.item_list = data.content;
        this.pageMeta.totalPages = data.totalPages;
      } catch (err) {
        console.log({ err });
      }
    },
    // 分頁搜尋
    pageFilter() {
      let pageParams = arguments[0];
      console.log(arguments[0]);
      this.pageMeta.page = pageParams.page;
      this.pageMeta.itemsPerPage = pageParams.itemsPerPage;
      this.getAuditPlanById();
    },
    // 側欄卡片
    async getAuditPlanSummary() {
      try {
        let res = await apiGetAuditPlanSummary(this.auditPlanId);
        console.log({ res });
        this.auditPlanSummary = res.data;
        this.executeYear = res.data.find((el) => el.isExecuteYear).year;
        // let data = res.data;
        // this.versionData = data.auditPlan;
        // this.manDaySummary = data.manDaySummary;
      } catch (err) {
        console.log({ err });
      }
    },
    changeSearchYear(year) {
      if (this.filters.searchYear == year) return;
      this.filters.searchYear = year;
    },
    // Filters
    async getRiskEvaluationList() {
      try {
        let res = await apiGetRiskEvaluationById(
          this.versionData.riskEvaluationId,
          { page: 1, size: 999 }
        );
        console.log("getRiskEvaluationList", { res });
        if (res.data.content.length != 0) {
          this.riskEvaluationList = res.data.content;
          this.riskTypes = this.riskEvaluationList.map((el) => el.type);
        }
        this.getAllocation(res.data.riskEvaluation.allocationId);
      } catch (err) {
        console.log(err);
      }
    },
    getScheduleYears() {
      let arr = [];
      for (let i = 0; i < 3; i++) {
        arr.push((+this.filters.searchYear + i).toString());
      }
      this.scheduleYears = arr;
    },
    async getAllocation(allocationId) {
      try {
        let res = await apiGetAllocationSettingsById(allocationId);
        console.log("getAllocation", { res });
        if (res.data.content != undefined) {
          let rrs = res.data.content.rrs;
          this.rrsLevels = rrs.reduce((acc, cur) => {
            if (cur.level !== acc.level) {
              acc.push(cur.level);
            }
            return acc;
          }, []);
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 初稿 <-> 草稿
    checkHasEditItem(status) {
      let hasEditItem = this.item_list.find((el) => el.edit);
      console.log({ hasEditItem });
      if (hasEditItem != undefined) {
        this.alert = {
          open: true,
          type: "leaveAlert",
          content: "您尚有未儲存之編輯，請問您是否仍要製作初稿?",
          onOk: () => this.changeStatus(status),
          onCancel: () => (this.alert.open = false),
        };
        return;
      }
      this.changeStatus(status);
    },
    async changeStatus(status) {
      //製作初稿
      try {
        let res = await apiToggleAuditPlanStatus(this.auditPlanId, {
          status,
        });
        console.log({ res });
        if (status == 1) {
          this.item_list.forEach((el) => (el.edit = false));
        }
        this.getAuditPlanById();
      } catch (err) {
        console.log({ err });
      }
    },
    // 編輯查核因子
    async editFactor(item) {
      try {
        let payload = {
          scheduleYear: item.scheduleYear,
          manDay: item.manDay,
          notes: item.notes,
        };
        console.log({ payload });
        let res = await apiEditFactor(this.auditPlanId, item.id, payload);
        // let res = await apiEditFactor(payload);
        console.log({ res });
        if (res.data.message == "success") {
          item.edit = false;
        }
      } catch (err) {
        console.log({ err });
      }
    },
    // === Dialogs ===
    //簽核軌跡
    signHistory() {
      this.signHistoryDialog = true;
      // TODO:
    },
    //匯入資料
    importDialogOpen() {
      this.importDialog = {
        open: true,
        type: "auditPlan",
      };
    },
    importSubmit() {
      console.log("submit");
      // TODO: Deal with submit
      // ------------
      //if Error
      this.importDialog = {
        ...this.importDialog,
        errors: ["格式錯誤", "缺少XX欄位"],
      };
    },
  },
};
</script>
<style scoped lang="scss">
.titleWidth {
  max-width: 520px;
  min-width: 450px;
}
.filterInput {
  max-width: 220px;
}
.riskLevCardsParent {
  height: calc(100vh - 160px);
  overflow: hidden;
}
.riskLevCards {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 17px;
  box-sizing: content-box;
}
.auditPlan_table {
  &:deep(.v-data-table__wrapper) {
    td {
      max-width: 150px;
      word-break: break-all;
    }
  }
}
.fs-13 {
  font-size: 13px;
}
</style>
