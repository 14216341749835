<template>
  <v-card
    class="pa-3 levCard mb-3 mx-1"
    :class="searchYear == item.year ? 'active' : ''"
    @click="$emit('changeSearchYear', item.year)"
  >
    <div class="d-flex align-baseline">
      <span class="text-subtitle-1">{{ item.year }}</span>
      <span class="ml-auto"
        >排定查核 <span class="error--text">{{ item.total }}</span> 項</span
      >
    </div>
    <v-divider class="my-2"></v-divider>
    <div class="d-flex align-center riskZone">
      <div class="diamond mr-2"></div>
      高風險 <span class="error--text mx-2">{{ item.high }}</span> 項
    </div>
    <div class="d-flex align-center riskZone">
      <div class="diamond mr-2"></div>
      中風險 <span class="error--text mx-2">{{ item.mid }}</span> 項
    </div>
    <div class="d-flex align-center riskZone">
      <div class="diamond mr-2"></div>
      低風險 <span class="error--text mx-2">{{ item.low }}</span> 項
    </div>
    <div v-show="item.isExecuteYear" class="text-right mt-3 thisYearChip">
      <v-chip color="fubonGreen" dark small class="px-2">執行年度</v-chip>
    </div>
  </v-card>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
    },
    searchYear: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/main.scss";
.levCard {
  * {
    font-size: 14px;
  }
  border-radius: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15) !important;
  position: relative;
  width: 192px;
  box-sizing: border-box;
  &.active {
    box-shadow: 0px 4px 8px rgba($fubonBlue, 0.5) !important;
  }
}

.riskZone {
  letter-spacing: 1px;
  .diamond {
    @include boxSize(8px);
    background-color: $fubonBlue;
    transform: rotate(45deg);
  }
  margin-bottom: 6px;
}
.thisYearChip {
  position: absolute;
  bottom: 8px;
  right: 4px;
  span {
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 1px;
  }
}
</style>
>
